import React from 'react'

export const GroovyPhone = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="762.056"
      height="1045.568"
      className={className}
      viewBox="0  0 762.056 1045.568"
    >
      <defs>
        <radialGradient
          id="radial-gradient"
          cx="210.468"
          cy="188.325"
          fx="339.2986"
          fy="393.2223"
          r="204.341"
          gradientTransform="matrix(.905 -.038 .048 1.149 10.926 -19.975)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0658ff" />
          <stop offset=".49" stopColor="#2d9cfc" />
          <stop offset="1" stopColor="#1d49fa" />
        </radialGradient>
        <linearGradient
          id="linear-gradient"
          y1=".5"
          x2="1"
          y2=".5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset=".21" stopColor="#5ec6cc" />
          <stop offset=".51" stopColor="#157fff" />
          <stop offset="1" stopColor="#5ec6cc" />
        </linearGradient>
        <radialGradient
          id="radial-gradient-2"
          cx="198.033"
          cy="175.716"
          r="193.373"
          gradientTransform="matrix(.898 -.038 .048 1.14 11.839 -17.036)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#2d9cfc" />
          <stop offset=".9" stopColor="#0658ff" />
        </radialGradient>
        <linearGradient
          id="linear-gradient-2"
          y1=".5"
          x2="1"
          y2=".5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#f47b31" />
          <stop offset=".48" stopColor="#f5cc8e" />
          <stop offset="1" stopColor="#f47b31" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          y1=".5"
          x2="1"
          y2=".5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#2dbffc" />
          <stop offset="1" stopColor="#2d9cfc" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          y1=".5"
          x2="1"
          y2=".5"
          xlinkHref="#radial-gradient"
        />
        <radialGradient
          id="radial-gradient-3"
          cx="180.57"
          cy="177.97"
          r="181.911"
          gradientTransform="matrix(.986 0 0 .986 2.612 2.574)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".01" stopColor="#e65c54" />
          <stop offset=".53" stopColor="#ff7c50" />
          <stop offset="1" stopColor="#e65c54" />
        </radialGradient>
      </defs>
      <g id="Group_645" data-name="Group 645" transform="translate(-3003 -984)">
        <g id="phone-blob-7" transform="translate(3018.01 1372.992)">
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1" data-name="Layer 1">
              <path
                id="Path_380"
                data-name="Path 380"
                d="M241.61 36.06c-23.28-26.2-34.67-41.91-69.17-34C140.82 8.94 86.1 39.44 42.38 82.63 18.26 107.22 1.9 133.87.25 158.19-2 182.7 10.54 204.89 31 225.11 73.68 265 142 299.22 175.52 333.27c29.45 26.05 40 46.4 64.83 48.84 23 3 60.27-11.86 103-42.61 31.56-23.63 63.42-56 73.84-86.14 11.7-30.55 1.95-58.81-20.35-84.21C375 143.62 340.46 121 311.09 98.76c-29.9-22.02-54.68-43.59-69.48-62.7z"
                opacity=".39"
                fill="url(#radial-gradient)"
              />
            </g>
          </g>
        </g>
        <g
          id="phone"
          data-name="Group 558"
          transform="translate(3044.147 2380)"
          style={{ position: 'relative' }}
        >
          <g
            id="Group_505"
            data-name="Group 505"
            transform="translate(85.853 -571.992)"
          >
            <path
              id="Path_319"
              data-name="Path 319"
              d="M301.266-254.857c-15.662 8.9-34.976 9.4-50.644.51q-.894-.508-1.777-1.055l-150.466-93.07a26.428 26.428 0 0 1-12.524-22.163 26.428 26.428 0 0 1 12-22.454l.691-.451a12.776 12.776 0 0 0-4.756 10.673 12.776 12.776 0 0 0 5.91 10.078l141.642 89.723a62.712 62.712 0 0 0 38.794 9.515 62.813 62.813 0 0 0 10.586-1.8l.187-.047q1.667-.438 3.313-.97l.271-.09a62.665 62.665 0 0 0 6.381-2.49c.159-.072.318-.143.476-.216q1.473-.684 2.918-1.449c.154-.081.306-.166.459-.248.9-.488 1.794-.978 2.666-1.512l8.354-5.121 348.1-213.367c7.906-4.846 5.34-18.03.718-24.921l.681-.416 6.156 4.916a25.867 25.867 0 0 1 9.035 21.7 25.867 25.867 0 0 1-12.266 20.055L309.018-259.261z"
              transform="translate(-85.853 526.203)"
              fill="#0080ee"
            />
          </g>
          <path
            id="Path_320"
            data-name="Path 320"
            className="cls-3"
            fill="#5a99ff"
            d="M667.289-573.565l-142.046-73.056a60.173 60.173 0 0 0-57.083 1.1L104.715-440.505a12.78 12.78 0 0 0-6.5 10.8 12.78 12.78 0 0 0 5.937 11.122L245.8-328.855a62.752 62.752 0 0 0 66.373.49L668.3-546.656a15.449 15.449 0 0 0 7.362-13.75 15.449 15.449 0 0 0-8.373-13.159zM596.8-602.13a2.753 2.753 0 0 1 2.753 2.753 2.753 2.753 0 0 1-2.753 2.753 2.753 2.753 0 0 1-2.754-2.753 2.753 2.753 0 0 1 2.754-2.753zm-28.333-12.282a1.653 1.653 0 0 1 2.228-.708l19.048 9.868a1.653 1.653 0 0 1 .707 2.228 1.652 1.652 0 0 1-.967.816 1.652 1.652 0 0 1-1.261-.108l-19.048-9.868a1.653 1.653 0 0 1-.708-2.229zm96.909 64.386L310.8-333.356a70.517 70.517 0 0 1-14.395 6.819 54.336 54.336 0 0 1-32.452 1.084c-6.552-1.877-10.807-4.17-16.6-7.758l-138.06-86.576-2.435-1.622a9.309 9.309 0 0 1-4.146-7.89 9.309 9.309 0 0 1 4.387-7.759l2.547-1.585 361.42-204.388a53.2 53.2 0 0 1 50.8-.969l10.7 5.558a2.526 2.526 0 0 1 1.461 1.843 2.526 2.526 0 0 1-.691 2.248l-1.153 1.315a5.982 5.982 0 0 0-1.387 5.016 5.982 5.982 0 0 0 3.066 4.2l80.583 41.969c7.242 3.871 11.432 2.777 18.581-.852a9.57 9.57 0 0 1 8.763-.048l22.849 11.778a12.029 12.029 0 0 1 6.483 10.258 12.029 12.029 0 0 1-5.751 10.685z"
            transform="translate(-4.454)"
          />
          <rect
            id="Rectangle_638"
            data-name="Rectangle 638"
            className="cls-3"
            width="59.024"
            height="6.849"
            rx="3.424"
            transform="matrix(.849 -.529 .529 .849 539.033 -463.425)"
          />
        </g>
        <g id="phone-blob-2" transform="translate(3203 984.004)">
          <g id="Layer_2-2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                id="Path_373"
                data-name="Path 373"
                d="M373.66 593.64c73.68 7.81 183.74-11.18 187.75-70.8 9-56.53-78.55-91.8-106.44-276.4-4-35.09-6.75-70.35-7.55-105.67-.65-29 3.94-58.65 2.38-87.2C449.11 40.89 445 27.94 436 19S414.54 6.08 402.25 3.56C322.36-12.79 243.93 30 179.62 73.08 112.89 118 56.45 165.54 27.05 208.12c-30.4 43-33.76 81.09-17.14 117.36 38.93 64.13 105.15 120.9 166.44 171 63.53 48.84 122.13 91 197.31 97.16z"
                fill="url(#linear-gradient)"
              />
            </g>
          </g>
        </g>
        <g id="phone-blob-1" transform="translate(3244.001 1012.016)">
          <g id="Layer_2-3" data-name="Layer 2">
            <g id="Layer_1-3" data-name="Layer 1">
              <path
                id="Path_378"
                data-name="Path 378"
                d="M332.56 536.34c64.56 8.52 160.43-6.76 162.82-61.17 6.78-51.49-70.41-85.36-98.26-254.58-5-38.13-8.84-76.37-11.86-114.7C382.74 74 386.45 38.57 357.91 17 341.16 4.37 319.46-.32 298.47 0c-54.2.86-101.82 26.84-145.35 57C95.63 96.77 47.18 139.16 22.28 177.52-3.49 216.27-5.72 251 9.5 284.45c35.24 59.33 94.2 112.46 148.73 159.38 56.46 45.84 108.48 85.47 174.33 92.51z"
                fill="#5ec6cc"
                opacity=".39"
              />
            </g>
          </g>
        </g>
        <g id="phone-blob-8" transform="translate(3030.003 1406.977)">
          <g id="Layer_2-4" data-name="Layer 2">
            <g id="Layer_1-4" data-name="Layer 1">
              <path
                id="Path_376"
                data-name="Path 376"
                d="M227.81 33.53C206 9.12 195.3-5.51 162.8 2 133 8.49 81.41 37.14 40.13 77.6c-22.78 23-38.25 48-39.88 70.69C-1.9 171.19 9.8 191.88 29 210.7c40 37.12 104.21 68.9 135.69 100.6 27.66 24.25 37.58 43.22 60.89 45.43 21.69 2.76 56.78-11.25 97.12-40.1 29.78-22.16 59.86-52.51 69.76-80.66 11.1-28.57 2-54.94-18.91-78.6C353 133.6 320.62 112.53 293 91.88c-28-20.48-51.31-40.55-65.19-58.35z"
                fill="url(#radial-gradient-2)"
              />
            </g>
          </g>
        </g>
        <g id="phone-blob-3" transform="translate(3023.996 1201)">
          <g id="Layer_2-5" data-name="Layer 2">
            <g id="Layer_1-5" data-name="Layer 1">
              <g id="Group_643" data-name="Group 643">
                <path
                  id="Path_374"
                  data-name="Path 374"
                  d="M575.16 186c-10.62 44-42 86.63-73.23 126.68-31.76 39.19-63.27 75.85-87.4 88.3-26.86 14.34-41.11-6.31-60.38-28.07-18.74-21.76-42.51-44.63-76-34.82C232.07 350.78 235 407.14 201 405c-23.5-1.14-54.79-32.22-89.9-65.22-34.45-34-72.72-69.9-94.83-103.64-29.71-42.34-17.46-98.32 26.46-149.71 42.2-50 116.09-95.45 170-84.89 91.02 14.1 106.56 144.32 158.4 134.46C415 129.51 430.24 39.8 487 36.11c27.83-1.83 56 19.87 73 49.18C577.32 114.82 583.43 152 575.16 186z"
                  fill="#ed962e"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="phone-blob-4" transform="translate(3003.001 1164.998)">
          <g id="Layer_2-6" data-name="Layer 2">
            <g id="Layer_1-6" data-name="Layer 1">
              <g id="Group_644" data-name="Group 644" opacity=".82">
                <path
                  id="Path_375"
                  data-name="Path 375"
                  d="M617.59 221.77c-11.41 52.52-45.16 103.3-78.64 151-34.1 46.73-67.94 90.44-93.85 105.29-28.84 17.09-44.14-7.53-64.83-33.47-20.12-25.95-45.64-53.22-81.65-41.52-49.43 15.14-46.27 82.34-82.82 79.76-25.23-1.37-58.83-38.43-96.53-77.77-37-40.53-78.08-83.35-101.82-123.58C-14.43 231.03-1.28 164.28 45.88 103 91.2 43.42 170.53-10.76 228.37 1.84 326.16 18.65 342.83 173.92 398.5 162.2c47.08-7.77 63.5-114.75 124.37-119.14 29.87-2.18 60.17 23.69 78.41 58.64 18.62 35.21 25.19 79.49 16.31 120.07z"
                  opacity=".39"
                  fill="url(#linear-gradient-2)"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="phone-blob-5" transform="translate(3182.001 1403.998)">
          <g id="Layer_2-7" data-name="Layer 2">
            <g id="Layer_1-7" data-name="Layer 1">
              <path
                id="Path_377"
                data-name="Path 377"
                d="M124.4 238.29c-41.87-40.18-80.11-81.55-103.65-113.78-23.09-32.39-30.9-54.89-2.51-62.22 25.92-8.37 70.67-10.29 96.5-20.62 28.08-9.29 39.5-24.13 50.38-34.27 10.47-8.65 27.6-14.51 54.78 10.86 25.14 22.93 67.65 86.83 92.35 175 26.23 87.5 9.28 142.74-38.62 134.8-47.8-5.44-106.32-49.73-149.23-89.77z"
                fill="#ed6b56"
              />
            </g>
          </g>
        </g>
        <g id="phone-blob-6" transform="translate(3164.01 1404.002)">
          <g id="Layer_2-10" data-name="Layer 2">
            <g id="Layer_1-10" data-name="Layer 1">
              <path
                id="Path_381"
                data-name="Path 381"
                d="M139.67 256.06C92.85 212.13 50 167 23.55 131.88c-26-35.26-34.92-59.64-3.67-67.13 28.49-8.65 77.89-10.06 106.27-20.83C157 34.31 169.42 18.48 181.29 7.7c11.43-9.17 30.27-15.25 60.65 12.51 28.1 25.08 76 94.61 104.53 190 30.24 94.72 12.33 154-40.69 144.78-52.86-6.53-118.14-55.13-166.11-98.93z"
                opacity=".44"
                fill="url(#radial-gradient-3)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
